import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import SectionRightImage from 'components/section-right-image';

const About = () => {
  const data = useStaticQuery(graphql`
    query portfolioPageAboutQuery {
      json: portfolioJson(id: { eq: "portfolio-about" }) {
        image {
          childImageSharp {
            fluid(maxWidth: 1200, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
              presentationWidth
              presentationHeight
            }
          }
        }
      }
      content: portfolioAboutJson {
        title
        body
      }
    }
  `);

  if (!data?.content) {
    return null;
  }

  const about = data.content;

  return (
    <SectionRightImage
      contents={about}
      image={data.json.image}
      className="top-left-full-shadow-corner pb-0"
    />
  );
};

export default About;
