import React, { FC } from 'react';
import Image from 'gatsby-image';
import {
  AccordianBodyWrapper,
  AccordionItemWrapper,
  ButtonContainer,
  AccordionItemImageWrapper,
  AccordionHeaderLabel,
  AccordianExtendedCopyWrapper,
  AccordionHeaderWrapper,
} from './accordion.style';
import Text from 'components/ui/text';
import ReactMarkdown from 'react-markdown';
import Button from 'components/ui/button';
import Heading from 'components/ui/heading';
import parse from 'html-react-parser';
import { useExpandable } from 'utils/useExpandable';
import ToggleButton from 'components/ui/toggle-button';

export enum ColorType {
  primary = 'primary',
  black = 'black',
}

export interface AccordionItemType {
  id: string;
  excerpt: string;
  extended: string;
  image: any;
  logo: any;
  visitURL?: string;
  title?: string;
  button?: {
    link: string;
    title: string;
  };
  label?: {
    text: string;
    color: ColorType;
  };
  titleStyling?: object;
  accordionItemStyle?: object;
  imgStyle?: object;
}

const AccordionItem: FC<AccordionItemType> = ({
  excerpt,
  title,
  extended,
  image,
  logo,
  visitURL,
  button,
  label,
  titleStyling,
  accordionItemStyle,
  imgStyle,
}) => {
  const { expandedRef, isExpanded, expandedHeight, toggleElementExpanded } =
    useExpandable();

  return (
    <AccordionItemWrapper {...accordionItemStyle}>
      <AccordionItemImageWrapper>
        {image && (
          <Image
            fluid={image.childImageSharp.fluid}
            fixed={image.childImageSharp.fixed}
            style={imgStyle}
          />
        )}
      </AccordionItemImageWrapper>
      <AccordianBodyWrapper>
        <AccordionHeaderWrapper>
          {logo && <img src={logo.publicURL} alt={'logo'} />}
          {label && label.text && (
            <AccordionHeaderLabel color={label.color}>
              {label.text}
            </AccordionHeaderLabel>
          )}
        </AccordionHeaderWrapper>
        {title && <Heading {...titleStyling}>{parse(title) as string}</Heading>}
        <Text>
          <ReactMarkdown>{excerpt}</ReactMarkdown>
          <AccordianExtendedCopyWrapper
            aria-expanded={isExpanded}
            expandedHeight={expandedHeight}
            ref={expandedRef}
          >
            <ReactMarkdown>{extended}</ReactMarkdown>
          </AccordianExtendedCopyWrapper>
        </Text>
        {visitURL && (
          <ButtonContainer>
            <ToggleButton
              onClick={toggleElementExpanded}
              isOpen={isExpanded}
              openText={'Collapse'}
              closedText={'Expand'}
            />
            <Button to={visitURL} varient="texted">
              Visit
            </Button>
          </ButtonContainer>
        )}
        {button && button.title && (
          <Button to={button.link} varient="texted">
            {button.title}
          </Button>
        )}
      </AccordianBodyWrapper>
    </AccordionItemWrapper>
  );
};

AccordionItem.defaultProps = {
  titleStyling: {
    m: '15px 0 24px',
  },
  imgStyle: {
    display: 'block',
    margin: '0 auto',
  },
};

export default AccordionItem;
