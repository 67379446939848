import React, { useEffect, useRef, useState } from 'react';

export const useExpandable = () => {
  const [isExpanded, setExpanded] = useState(false);
  const [expandedHeight, setExpandedHeight] = useState<number | null>(null);
  const expandedRef = useRef<HTMLDivElement>(null);

  const toggleElementExpanded = (e: React.BaseSyntheticEvent) => {
    e.preventDefault();
    e.target.blur();
    setExpanded((s) => !s);
  };

  useEffect(() => {
    const getElementScrollHeight = () => {
      if (expandedRef?.current) {
        setExpandedHeight(expandedRef?.current?.scrollHeight);
      }
    };

    getElementScrollHeight();

    window.addEventListener('resize', getElementScrollHeight);

    return () => {
      window.removeEventListener('resize', getElementScrollHeight);
    };
  }, [setExpandedHeight]);

  return {
    expandedRef,
    isExpanded,
    expandedHeight,
    toggleElementExpanded,
  };
};
