import React, { FC, SyntheticEvent } from 'react';
import { StyledButton } from './toggle-button.style';

interface Props {
  onClick: (e: SyntheticEvent) => void;
  isOpen: boolean;
  openText: string;
  closedText: string;
}

const ToggleButton: FC<Props> = ({ onClick, isOpen, openText, closedText }) => (
  <StyledButton onClick={onClick} varient="texted" isExpanded={isOpen}>
    {isOpen ? openText : closedText}
  </StyledButton>
);

export default ToggleButton;
