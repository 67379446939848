import React from 'react';
import SEO from 'components/seo';
import Hero from 'components/hero';
import Layout from 'containers/layout/layout';
import Header from 'containers/layout/header';
import Footer from 'containers/layout/footer';
import { useStaticQuery, graphql } from 'gatsby';
import PortfolioCallout from 'containers/portfolio/callout';
import About from 'containers/portfolio/about';
import PortfolioItemsArea from 'containers/portfolio/text-area';
import PortfolioInDevelopmentArea from 'containers/portfolio/inDevelopment';
import bgPortfolio from 'assets/images/bg/headers/Portfolio-Header.png';

const IndexPortfolioPage = ({ location }) => {
  const { portfolioHeroJson: data } = useStaticQuery(graphql`
    query portfolioHeroDataQuery {
      portfolioHeroJson {
        hero {
          title
        }
      }
    }
  `);

  return (
    <Layout location={location}>
      <SEO title="Portfolio" image={bgPortfolio} />
      <Header location={location} />
      <main className="site-wrapper-reveal">
        <Hero
          title={data?.hero?.title}
          button={data?.hero?.button}
          hero="portfolio"
        />
        <PortfolioCallout />
        <About />
        <PortfolioItemsArea />
        <PortfolioInDevelopmentArea />
      </main>
      <Footer />
    </Layout>
  );
};

export default IndexPortfolioPage;
