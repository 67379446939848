import styled from 'styled-components';
import { device } from 'theme';

export const AccordionWrapper = styled.section`
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 30px;
`;

export const AccordionItemWrapper = styled.article<{ boxShadow?: string, background?: string }>`
  display: flex;
  margin: 25px 0;
  padding: 75px 0;
  border-radius: 13px;
  position: relative;
  box-shadow: ${(props) => props.boxShadow || props.theme.outlines.boxShadow};
  background: ${(props) => props.background || props.theme.colors.white};

  @media ${device.small} {
    flex-direction: column;
  }
`;

export const AccordionHeaderLabel = styled.div`
  justify-self: flex-end;
  font-family: ${(props) => props.theme.fontFamily.heading};
  font-size: 20px;
  font-weight: 400;
  background-color: ${(props) =>
    props.color && (props.theme.colors[props.color] || props.color)};
  color: ${(props) => props.theme.colors.white};
  border-radius: 50%;
  height: 75px;
  width: 75px;
  line-height: 20px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  @media ${device.small} {
    justify-self: auto;
  }
`;

export const AccordionHeaderWrapper = styled.header`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  margin: 0 0 12px;

  @media ${device.small} {
    grid-template-columns: 1fr;
    grid-row-gap: 20px;
    margin: 20px 0;
  }
`;

export const AccordionItemImageWrapper = styled.div`
  margin: 0 25px 0 45px;
  flex: 1;

  @media ${device.small} {
    align-self: center;
    width: 100%;
    max-width: 300px;
    margin: 0;
    padding: 0 45px;
  }
`;

export const AccordianBodyWrapper = styled.div`
  padding-right: 45px;
  flex: 2;

  @media ${device.small} {
    padding: 0 45px;
  }
`;

export const AccordianExtendedCopyWrapper = styled.div< { expandedHeight: number | null }>`
  overflow: hidden;
  height: 0;
  transition: ${(props) => props.theme.transition};

  &[aria-expanded='true'] {
    height: ${(props) => (props.expandedHeight as number) + 10}px;
  }
`;

export const ButtonContainer = styled.footer`
  & > * {
    margin: 0 15px 0 0;
    width: 75px;
  }
`;
