import styled, { css } from 'styled-components';
import Button from '../button';

export const StyledButton = styled(Button)< { isExpanded?: boolean } >`
  ${(props) =>
    props.isExpanded &&
    css`
      &:before {
        content: '-';
        padding-right: 3px;
      }
      & {
        color: ${props.theme.colors.secondary};
      }
    `}
`;
