import SectionTextContent from 'components/section-text-content';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import Accordion from '../accordion';
import { InDevelopmentWrapper } from './inDevelopment.style';

interface AccordionItem {
  id: string;
  title: string;
  excerpt: string;
  extended: string;
  label: {
    text: string;
    color: string;
  };
  button: {
    title: string;
    link: string;
  };
  visitURL: string;
  logoMapping: {
    logoId: string;
  }
  logo?: any;
  image?: any;
}

interface JSONItem {
  id: string;
  title: string;
  image: any;
}

const InDevelopmentArea = () => {
  const data = useStaticQuery(graphql`
    query philosophyPagePortfolioInDevelopmentAreaQuery {
      content: portfolioDevelopmentJson(
        id: { eq: "portfolio-development-text" }
      ) {
        inDevelopment {
          body
          button {
            link
            title
          }
          header {
            subtitle
            title
          }
        }
      }
      portfolioImages: portfolioJson(id: { eq: "portfolio-items" }) {
        items {
          id
          title
          image {
            childImageSharp {
              fixed(
                width: 125
                height: 125
                webpQuality: 100
                quality: 100
                fit: INSIDE
              ) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
      items: portfolioDevelopmentJson(
        id: { eq: "portfolio-development-accordion-items" }
      ) {
        accordionItems {
          id
          title
          excerpt
          extended
          label {
            text
            color
          }
          button {
            title
            link
          }
          visitURL
          logoMapping {
            logoId
          }
        }
      }
    }
  `);

  if (!data?.items) {
    return null;
  }

  const {
    items: { accordionItems },
  } = data;

  accordionItems.forEach((item: AccordionItem) => {
    const placeholder = data.portfolioImages.items.find(
      (jsonItem: JSONItem) => jsonItem.id === 'placeholder',
    );
    if (placeholder) {
      item.image = placeholder.image;
    }

    return item;
  });

  return (
    <InDevelopmentWrapper className="left-red-slice md-no-bg-image">
      {data?.content?.inDevelopment && (
        <SectionTextContent {...data.content.inDevelopment} />
      )}
      <Accordion
        items={accordionItems}
        accordionItemStyle={{
          boxShadow: 'inset 0 1px 13px 0 rgba(96,96,96,0.50)',
          background: '#FBFBFB',
        }}
      />
    </InDevelopmentWrapper>
  );
};

export default InDevelopmentArea;
