import React, { FC } from 'react';
import { AccordionWrapper } from './accordion.style';
import AccordionItem, { AccordionItemType } from './accordionItem';

interface Props {
  accordionItemStyle?: object;
  items: AccordionItemType[];
}

const Accordion: FC<Props> = ({ items, accordionItemStyle }) => {
  return (
    <div className="">
      <AccordionWrapper>
        {items.map((item: AccordionItemType) => (
          <AccordionItem
            key={item.id}
            {...item}
            accordionItemStyle={accordionItemStyle}
          />
        ))}
      </AccordionWrapper>
    </div>
  );
};

Accordion.defaultProps = {
  accordionItemStyle: {},
};

export default Accordion;
