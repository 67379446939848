import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import Callout from 'components/callout';

const PortfolioCallout = () => {
  const data = useStaticQuery(graphql`
    query PortfolioCallout {
      content: portfolioCalloutJson {
        title
        body
      }
    }
  `);

  if (!data?.content) {
    return null;
  }

  const { title, body } = data.content;

  return <Callout className="" title={title} body={body} />;
};

export default PortfolioCallout;
