import SectionTextContent from 'components/section-text-content';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import Accordion from '../accordion';
import { TextAreaWrapper } from './textArea.style';

interface AccordionItem {
  id: string;
  title: string;
  excerpt: string;
  extended: string;
  label: {
    text: string;
    color: string;
  };
  button: {
    title: string;
    link: string;
  };
  visitURL: string;
  logoMapping: {
    logoId: string;
  }
  logo?: any;
  image?: any;
}

interface Logo {
  id: string;
  image: any;
}

interface JSONItem {
  id: string;
  title: string;
  image: any;
}

const PortfolioItemsArea = () => {
  const data = useStaticQuery(graphql`
    query philosophyPagePortfolioTextAreaQuery {
      textArea: portfolioTextAreaJson(id: { eq: "portfolio-text-area" }) {
        textArea {
          body
          button {
            link
            title
          }
          header {
            subtitle
            title
          }
        }
      }
      logos: allLogosJson {
        nodes {
          id
          image {
            publicURL
          }
        }
      }
      portfolioImages: portfolioJson(id: { eq: "portfolio-items" }) {
        items {
          id
          title
          image {
            childImageSharp {
              fluid(
                maxWidth: 250
                maxHeight: 250
                webpQuality: 100
                quality: 100
                fit: INSIDE
              ) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      content: portfolioTextAreaJson(id: { eq: "portfolio-accordion-items" }) {
        accordionItems {
          id
          title
          excerpt
          extended
          label {
            text
            color
          }
          button {
            title
            link
          }
          visitURL
          logoMapping {
            logoId
          }
        }
      }
    }
  `);

  const {
    content: { accordionItems },
  } = data;

  if (accordionItems.length === 0) {
    return null;
  }

  accordionItems.forEach((item: AccordionItem) => {
    const logoMatch = data.logos.nodes.find(
      (logo: Logo) => logo.id === item.logoMapping?.logoId,
    );

    if (logoMatch) {
      item.logo = logoMatch.image;
    }

    const imageMatch = data.portfolioImages.items.find((jsonItem: JSONItem) => {
      if (jsonItem.title) {
        return jsonItem.title === item.title?.toLowerCase();
      }

      if (jsonItem.id) {
        return jsonItem.id === item.logoMapping?.logoId;
      }
    });

    if (imageMatch) {
      item.image = imageMatch.image;
    } else {
      const placeholder = data.portfolioImages.items.find(
        (jsonItem: JSONItem) => jsonItem.id === 'placeholder',
      );
      item.image = placeholder.image;
    }

    return item;
  });

  return (
    <TextAreaWrapper className="">
      <SectionTextContent
        {...data.textArea.textArea}
        sectionTitleStyle={{
          mb: '30px',
          color: 'white',
          align: 'center',
        }}
        sectionBodyStyle={{
          color: 'white',
        }}
      />
      <Accordion items={accordionItems} />
    </TextAreaWrapper>
  );
};

export default PortfolioItemsArea;
