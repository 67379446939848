import styled from 'styled-components';
import topBlackGreyGradient from 'assets/images/bg/black-to-grey-gradient.png';
import { device } from 'theme';

export const TextAreaWrapper = styled.div`
  background-image: url(${topBlackGreyGradient});
  background-repeat: no-repeat;
  background-size: 100% 375px;
  background-position: top;

  @media ${device.small} {
    background-size: 100% 500px;
  }
`;
